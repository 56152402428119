import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 4
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"THREE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"What are triggers?"}
        answers={[
          "Anything that sets off the prefrontal cortex",
          "The things that cause stress reactions",
          "Something that coaches can’t do anything about",
          "Things that all brains react to in the same way",
        ]}
        correctAnswerNumber={1}
        answerDetail={
          "A trigger is anything that sets off the amygdala. Triggers are important for coaches to understand because they are the things that set off your players. The more you understand them, the more you will be able to prevent them."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 3, 5)
